.sidebar-main {
    display: grid;

    .menu-link {
        padding: 30px 20px 20px 20px; 

        .logo {
            border-bottom: 1px solid #000d39;
            padding: 0 20px 50px; 
            margin-bottom: 10px;

            img {
                height: 45px;
            }
        }

        .h6 {
            font-size: 14px;
            padding: 0 20px;
            color: var(--white);
        }

        .nav {
            display: grid;
            gap: 16px;
            margin-top: 20px;
            line-height: 1.5em;

            a {
                font-size: 14px;
                font-weight: 300;
                color: var(--white);
                display: inline-flex;
                height: 45px;
                border-radius: 5px;
                align-items: center;
                gap: 10px;
                padding: 0 20px;
                @media (max-width: 1399.98px){

                    padding: 0 16px;
                }
                svg{
                    font-size: 22px;
                }
                &.active {
                    background: var(--blue);
                    font-weight:400;
                }
            }
        }
    }

    .sidebar-footer {
        padding: 30px 20px 20px 20px; 
        margin-top: auto;
        display: grid;

        a {
            font-size: 14px;
            color: var(--white);
            display: inline-flex;
            height: 45px;
            border-radius: 5px;
            align-items: center;
            gap: 10px;
            padding: 0 20px;
            @media (max-width: 1399.98px){
                padding: 0 16px;
            }
            svg{
                font-size: 22px;
            }
        }
    }
}