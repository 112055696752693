.quick-content {
	background-image: url("../../../../public/images/quick-bg.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	border-radius: 12px;
	overflow: hidden;
	z-index: 1;
	color: var(--white);
	padding: 15px 30px 20px;
	@media (max-width: 575.98px) {
		padding: 15px 15px;
	}
	&::before {
		content: "";
		background-color: #000d39;
		opacity: 0.8;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	.h1 {
		span {
			font-size: 14px;
			padding-left: 10px;
			font-weight: 400;
			svg {
				color: #7ed10d;
				font-size: 14px;
			}
			@media (max-width: 575.98px) {
				display: block;
				margin-left: -10px;
				line-height: 2em;
			}
		}
	}
	ul {
		display: grid;
		gap: 18px;
		padding-top: 20px;
		li {
			font-size: 14px;
			font-weight: 300;
			line-height: 1em;
			display: flex;
			align-items: center;
			@media (max-width: 767.98px) {
				display: grid;
			}
			svg {
				font-size: 14px;
			}
			.request-text {
				padding-left: 15px;
			}
		}
	}
}
