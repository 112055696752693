.tracking-content {
    padding: 20px;
    display: grid;
    overflow: hidden;


    .h5 {
        color: var(--black);
        font-weight: 400;
        padding: 12px 0 8px;

        span {
            font-weight: 500;
        }
    }

    @media (max-width: 767px) {
        .tracking-content {
            margin-bottom: 25px !important;
        }
    }
      

    .table {
        th {
            text-align: left;
            font-size: 14px;
            color: var(--black);
            font-weight: 400;
            border-bottom: 1px solid #e0e0e0;
        }

        tr {
            padding-top: 15px;

            td {
                font-size: 14px;
                color: #484c58;
                font-weight: 300;
                span {
                    text-align: center;
                    width: 100%;
                    display: inline-block;
                }
            }
        }
    }
}



.table-responsive {
    overflow: hidden;
    position: relative;
    max-width: 600px;
    .table-con{
        overflow-x: auto;
    }
}

.table { 
    margin-bottom: 1rem;
    vertical-align: top; 
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

.table> :not(caption)>*>* {
    padding: 0.3rem 0.2rem;
    @media (max-width: 575.98px){
        padding: 0.2rem 0.5rem;
    }
    &:first-child {
        padding-left: 0;
        padding-right: 0;
    }
}

.mt-auto {
    margin-top: auto;
}

 div.main-contents > div.container > div:nth-child(3) > div.tracking-content.box-bg svg {
height: 13px;

 }