.top-bar{
    background-image: linear-gradient(180deg, #000d39 0%, #000d39ce 100%);
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    display: none;
    @media (max-width: 991.98px){
        display: flex;
        margin-bottom: 30px;
    }
    button{
        border: 0;
        background: none;
        font-size: 25px;
        color: var(--white);
        padding: 5px 5px 0px;
        margin: 0;
        cursor: pointer;
    }
}