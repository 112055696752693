.description-content{
    padding: 20px;
    display: grid;
    grid-template-rows: 2fr ;
    p{
        max-width: 100%;
        // padding-top: px;
        font-size: 14px;
        line-height: 1.2;
    }
    a{
        margin-top: 2px; 
    }
}
ul li[data-list="bullet"] {
    list-style-type: disc !important;
    list-style: disc !important;
    span {
        font-weight: normal !important;
    }
}

ul {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
}
/* CSS */
// ul {
//     list-style-type: disc !important !important; /* Override other styles */
// }
// 