// Google Fonts
// font-family: 'Poppins', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");
:root {
	--white: #fff;
	--black: #000d39;
	--blue: #0089eb;
	--grey: #484c58;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	box-shadow: none;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1.2;
	background: var(--white);
	color: var(--black);
	font-family: "Poppins", sans-serif;

	// background-image: url("");
	// background-position: center top;
	// background-size: cover;
	// background-repeat: no-repeat;

	overflow: hidden;
}

ol,
ul {
	list-style: none;
}
strong {
	font-weight: 600;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.navbar-toggler {
	box-shadow: none !important;
	outline: none;
	border: 0;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-bottom: 0;
}

.h1,
h1 {
	font-size: 25px;
	font-weight: 600;
}
.h2,
h2 {
	font-size: 24px;
	font-weight: 600;
	@media (max-width: 767.98px) {
		font-size: 20px;
	}
}
.h3,
h3 {
	font-size: 16px;
	@media (max-width: 767.98px) {
		font-size: 14px;
		font-weight: 500;
	}
}
.h4,
h4 {
	font-size: 14px;
	@media (max-width: 767.98px) {
		font-size: 14px;
	}
}
.h5,
h5 {
	font-size: 14px;
}
.h6,
h6 {
	font-size: 14px;
}
p {
	font-size: 18px;
	font-weight: 300;
	line-height: 1.5;
	color: #484c58;
	@media (max-width: 767.98px) {
		font-size: 18px;
	}
}

.fw-semibold {
	font-weight: 500;
}
.fw-bold {
	font-weight: 600;
}

.border-radius {
	border-radius: 100%;
}

.mt-24 {
	margin-top: 24px;
}
.mb-24 {
	margin-bottom: 24px;
}
.pt-24 {
	padding-top: 24px;
}
.pb-24 {
	padding-bottom: 24px;
}

a {
	text-decoration: none;
}

.row-gap {
	row-gap: 30px;
}

.container {
	margin: 0 auto;
	padding: 0 40px;
	@media (min-width: 1400px) {
		max-width: 1380px;
	}
	@media (max-width: 575.98px) {
		padding: 0 24px;
		margin-bottom: 25px;
	}
}

.btn-lg {
	display: block !important;
	padding: 0 10px;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	outline: none;
	transition: all 0.3s;
	min-width: 170px;
	height: 40px;
	border-radius: 5px;
	background-color: var(--blue);
	border: 0;
	cursor: pointer;
	&:hover {
		transition: all 0.3s;
	}
}

.btn-lg-a {
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	outline: none;
	transition: all 0.3s;
	min-width: 170px;
	height: 40px;
	border-radius: 5px;
	background-color: var(--blue);
	border: 0;
	cursor: pointer;
	&:hover {
		transition: all 0.3s;
	}
}

div.mb-auto > button:nth-child(2) {
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	background-color: #000d39;
	color: #ffffff

}

.mb-auto button {
margin-top: 10px;
}

.show-more {
	font-size: 14px;
	color: var(--blue);
	font-weight: 500;
	border-bottom: 2px solid var(--blue);
	position: relative;
	padding-bottom: 2px;
	display: inline-block;
	&::after {
		content: "\f107";
		font-family: "Font Awesome 6 free";
		font-weight: 900;
		font-size: 12px;
		padding-left: 9px;
	}
	@media (max-width: 1399.98px) {
		font-size: 14px;
	}
	@media (max-width: 767.98px) {
		font-size: 14px;
	}
}

.dashboard-subhead {
	font-size: 13px;
	color: #b3bccd;
	text-transform: uppercase;
	font-weight: 600;
}

.dashboard-title {
	font-size: 8px;
}

//

.wrapper-app {
	display: flex;

	.sidebar-main {
		width: 340px;
		height: 100vh;
		background-image: linear-gradient(180deg, #000d39 0%, #545d7a 130%);
		transition: all 0.3s;
		@media (max-width: 1399.98px) {
			width: 400px;
		}
	}
	.main-contents {
		width: 100%;
		height: 100vh;
		overflow-y: scroll;
		padding: 30px 0 0 0;
	}
}

.breadcrumbs {
	padding: 0 0 20px 40px;
	font-size: 13px;
	color: #848ba0;
}

.breadcrumbs a {
	font-size: 13px;
	color: #848ba0;
	text-underline-offset: 0.5em;
}

.breadcrumbs a:hover {
	text-decoration: underline;
}

@media (max-width: 991.98px) {
	.wrapper-app {
		.sidebar-main {
			position: absolute;
			z-index: 111;
			width: 330px;
			left: -330px;
			height: 106vh;
		}
		.main-contents {
			padding-top: 0;
			overflow: auto;
		}
	}
	.sidebar-active {
		.sidebar-main {
			left: 0;
		}
	}
}

.grid-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 25px;
	@media (max-width: 991.98px) {
		grid-template-columns: 1fr;
	}
}

.grid-2-wide {
	display: grid;
	grid-template-columns: 2fr 1fr; /* Adjust the width of the first column */
	gap: 25px;
}

@media (max-width: 991.98px) {
	.grid-2-wide {
	grid-template-columns: 1fr; /* Reset to one column for smaller screens */
	}
}

.grid-2 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 24px;
	* {
		box-sizing: border-box;
	}
	white-space: initial;
	word-break: break-all;
	@media screen and (min-width: 992px) {
		flex-direction: row;
		justify-content: space-between;
		> div {
			width: calc(50% - 12px);
			display: block;
			flex-grow: 1;
		}
	}
}

.box-bg {
	border-radius: 10px;
	background-color: #f8fafe;
	border: 1px solid #dddddd;
}

.d-none {
	display: none;
}

@media (max-width: 991.98px) {
	.d-block {
		display: block;
	}
}
@media (min-width: 991.98px) {
	.d-block {
		display: none;
	}
	.d-lg-block {
		display: block;
	}
}
.pb-24px {
	padding-bottom: 48px;
}
.logo {
	a {
		display: flex;
		align-items: center;
		color: var(--white);
		gap: 16px;
		h6 {
			margin-top: 4px;
			color: var(--white);
			margin-bottom: 6px;
			font-weight: 600;
		}
		.slogan {
			opacity: 0.8;
			margin: 0;
			font-size: 12px;
			display: block;
		}
	}
}
.dashboard-card {
	background: #f8fafe;
}
.last-quarter-metrics {
	font-size: 17px;
	margin-bottom: 5px;
	margin-top: 5px;
	text-transform:none; 
	font-weight: 500;
}




.dashboard-title {
	font-size: 21px;
	font-weight: 500;
	padding-bottom: 15px;
}

.main-dashboard-title {
	padding-bottom: 15px;
}

.row {
	display: flex;
	flex-wrap: wrap;
	row-gap: 24px;
	> * {
		flex: 0 0 100%;
	}
	* {
		box-sizing: border-box;
	}
	@media screen and (min-width: 992px) {
		margin-left: -12px;
		margin-right: -12px;
	}
	.col-sm-6 {
		@media screen and (min-width: 576px) {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	@media screen and (min-width: 1200px) {
		.col-xl-3 {
			flex: 0 0 20%;
			max-width: 20%;
			padding-left: 12px;
			padding-right: 12px;
		}
		.col-xl-6 {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 12px;
			padding-right: 12px;
		}
		.col-xl-4 {
			flex: 0 0 30%;
			max-width: 30%;
			padding-left: 12px;
			padding-right: 12px;
		}
	}
}
.mb-24 {
	margin-bottom: 24px;
}
.dashboard-card {
	border-radius: 20px;
	padding: 18px 24px;
	min-height: 170px;
	border: 1px solid #f1f1f1;
	.h1 {
		font-size: 40px;
		margin-bottom: 10px;
		text-transform: uppercase;
		small {
			font-size: 65%;
		}
	}
	.h2 {
		font-size: 22px;
		font-weight: 500;
	}
	@media screen and (max-width: 1350px) {
		.h1 {
			font-size: 24px;
		}
		.h2 {
			font-size: 18px;
		}
	}
	@media screen and (max-width: 575px) {
		.h1 {
			font-size: 24px;
		}
		.h2 {
			font-size: 18px;
		}
	}
	@media screen and (min-width: 1400px) and (max-width: 1550px) {
		padding: 18px 10px;
		.h1 {
			font-size: 40px;
		}
		.h2 {
			font-size: 18px;
		}
	}
}
.indicators {
	display: flex;
	flex-direction: column;
	gap: 8px;
	.indicator {
		font-size: 14px;
		display: flex;
		align-items: center;
		gap: 8px;
		.color {
			width: 10px;
			height: 10px;
		}
		.text {
			opacity: 0.8;
		}
	}
}
.dashboard-analytics-title {
	font-weight: 600;
	padding: 0 0 10px 20px;
	font-size: 18px;
	display: block;
}
.mb-10px {
	margin-bottom: 10px;
}
.__chart-wrap {
	display: flex;
	justify-content: space-between;
	// gap: 24px;
	align-items: center;
	.__chart {
		height: 60px;
		position: relative;
		flex-grow: 1;
		.borders {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			justify-content: space-between;
			display: flex;
			span {
				width: 1px;
				height: 100%;
				background: #dddddd;
				display: block;
			}
		}
		.bars {
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			transform: translateY(-50%);
			display: flex;
			justify-content: space-between;
			z-index: 1;
			span {
				flex-grow: 1;
				text-align: center;
				padding: 8px 10px;
				color: var(--white);
				font-size: 14px;
			}
		}
	}
	.indicators {
		@media screen and (min-width: 576px) {
			padding-inline: 20px;
		}
	}
}

.chart-container {
	padding-top: 20px;
}
.__table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 10px;
	tbody {
		tr {
			background: var(--white);
			border-radius: 10px;
		}
	}
	th,
	td {
		padding: 12px 10px;
		font-size: 13px;
		font-weight: 400;
		color: var(--black);
		border: 1px solid #ddd;
		&:not(:last-child) {
			border-right: none;
		}
		&:not(:first-child) {
			border-left: none;
		}
		&:last-child {
			border-radius: 0 15px 15px 0;
		}
		&:first-child {
			border-radius: 15px 0 0 15px;
		}
	}
	th {
		text-align: left;
	}
	td {
		text-align: center;
	}
	.id {
		color: #7a7f91;
	}
	min-width: 760px;
}
.table--responsive {
	overflow-x: auto;
}

.city {
	white-space: pre;
}
.city svg {
	vertical-align: sub;
	height: 16px;
}
.d-flex {
	display: flex;
}
.align-items-center {
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-space-between {
	justify-content: space-between;
}
.flex-column {
	flex-direction: column;
}

.show--more {
	font-size: 18px;
	color: var(--blue);
	font-weight: 500;
	border-bottom: 2px solid var(--blue);
	position: relative;
	padding: 0 3px 4px;
	display: inline-block;
	border: none;
	outline: none;
	background: transparent;
	cursor: pointer;
	span {
		text-decoration: underline;
	}
	@media (max-width: 1399.98px) {
		font-size: 14px;
	}
	@media (max-width: 767.98px) {
		font-size: 14px;
	}
}

.form-select {
	/* Remove default select appearance */
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	border: none;
	background: transparent;
	padding: 0;
	margin: 0;
	outline: none;

	/* Background image for the arrow */
	background-image: url(../public/arrow.svg);
	background-repeat: no-repeat;
	background-size: 9px;
	background-position: 70px 5px;

	/* Font and dimensions */
	font-family: "Poppins Light", sans-serif;
	height: 23px;
	width: 85px;
	font-size: 10px;

	/* Border and appearance */
	border: 1px solid #dbdfe6;
	border-radius: 5px;
	outline: none;
	box-shadow: none;
	padding-left: 10px;

	/* Text color and background */
	color: #535d7e;
	background-color: transparent;

	/* Vertically center the text within the select box in Safari */
	line-height: 20px; /* Adjust the line-height based on the select's height */
}

/* Style for the select options */
.form-select option {
	position: absolute;
	font-size: 12px;

	/* Center text within options */
}

.align-items-end {
	align-items: flex-end;
}
.gap-3 {
	gap: 5px;
}

.d-flex.flex-wrap.justify-content-end.align-items-center.gap-3 {
	padding-right: 20px;
}

.justify-content-end {
	justify-content: flex-end;
}
.kpi-btn {
	background: #ffffff;
	border-radius: 5px;
	border: 1px solid #f1f1f1;
	padding: 4px 10px;
	font-size: 9px !important;
	font-family: poppins;
	color: #b7b7b7;
	transition: all 0.3s;
	cursor: pointer;
	&:hover,
	&.active {
		background: var(--blue) !important;
		border-color: var(--blue) !important;
		color: var(--white);
	}
	position: relative;
	@media screen and (min-width: 1400px) and (max-width: 1550px) {
		font-size: 10px;
	}
}

.kpi-chart {
	padding-left: 20px;
}
.osrt-btn {
	background: #ffffff !important;
	border-radius: 5px;
	border: 1px solid #f1f1f1;
	padding: 4px 10px;
	font-size: 9px !important;
	color: #b7b7b7;
	transition: all 0.3s;
	cursor: pointer;
	position: relative;
	@media screen and (min-width: 1400px) and (max-width: 1550px) {
		font-size: 10px;
	}
	.badge {
		position: absolute;
		top: 0;
		right: 0;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #848ba0;
		z-index: 1;
	}
	&:not(:hover) {
		.__tooltip {
			display: none;
		}
	}
	@media screen and (max-width: 500px) {
		margin-left: auto;
	}
	&:hover,
	&.active {
		background: var(--blue) !important;
		border-color: var(--blue) !important;
		color: var(--white);
	}
	position: relative;
}
.__tooltip {
	position: absolute;
	bottom: 100%;
	right: 0;
	background: var(--white);
	width: 200px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	color: var(--black);
}
.flex-wrap {
	flex-wrap: wrap;
}
.dashboard-card {
	@media screen and (max-width: 370px) {
		&.dashboard-card-chart {
			padding-inline: 14px;
		}
	}
}
@media screen and (min-width: 768px) {
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
		padding-left: 12px;
		padding-right: 12px;
	}
}

.col-xxl-4 {
	@media screen and (min-width: 1400px) {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
		padding-left: 12px;
		padding-right: 12px;
	}
}
.__fade-in {
	animation: fadeIn 0.5s ease-in-out;
}
.label-area {
	display: flex;
	justify-content: center;
	margin-top: 16px;
	font-size: 12px;
	color: rgb(55, 61, 63);
	row-gap: 10px;
	column-gap: 16px;
	label {
		display: flex;
		align-items: center;
		.color {
			width: 12px;
			height: 12px;
			border-radius: 2px;
			margin-right: 3px;
		}
		cursor: pointer;
	}
}
.w-100 {
	width: 100%;
}
.text-center {
	text-align: center;
}
.line-chart {
	.apexcharts-tooltip,
	.apexcharts-toolbar {
		display: none !important;
	}
	.apexcharts-line {
		stroke-width: 2.5px;
	}
}

.pie-chart-container {
	display: flex;
	justify-content: center;
  }



.stacked-bar-chart {
	.apexcharts-data-labels {
		display: none !important;
	}
	.apexcharts-ycrosshairs,
	.apexcharts-toolbar {
		display: none !important;
	}
	.apexcharts-gridline,
	.apexcharts-gridline,
	.apexcharts-gridlines-horizontal {
		display: none !important;
	}
}
.pl-0 {
	padding-left: 0 !important;
	height: 400px
}
.pl-3 {
	padding-left: 20px;
}
.apexcharts-yaxis {
	display: none !important;
}
.form--select {
	font-size: 12px;
	border-radius: 5px;
	[class*="-indicatorSeparator"] {
		display: none;
	}
	[class*="-IndicatorsContainer"],
	[class*="-indicatorContainer"] {
		height: 26px;
		padding: 0 !important;
	}
	[class*="-ValueContainer"] {
		padding-right: 0 !important;
		padding-left: 5px !important;
	}
	.css-t3ipsp-control,
	.css-13cymwt-control {
		min-height: 26px;
		height: 26px !important;
		line-height: 12px !important;
	}
	* {
		box-shadow: none !important;
		outline: none !important;
		border-color: #e1e1e1 !important;
	}
	svg {
		color: #d9d9d9 !important;
		width: 16px;
		height: 25px;
		margin-right: 4px;
	}
}
.stacked-bar-chart {
	* {
		font-family: "Poppins", sans-serif !important;
	}
}
.form--select {
	white-space: pre;
	cursor: pointer;
	[class*="-menu"] {
		width: auto;
		min-width: 100%;
	}
}
.form--select__option {
	margin: 0 !important;
	cursor: pointer;
	&:first-child {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	&:last-child {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:hover {
		background: #f8fafe !important;
	}
}
.form--select__menu {
	margin: 0 !important;
	padding: 0 !important;
	border: 1px solid #e1e1e1 !important;
	&:hover {
		background: white !important;
	}
}
.form--select__option--is-selected:hover,
.form--select__option--is-selected {
	background-color: var(--blue) !important;
	color: #ffffff;
}
.form--select .css-t3ipsp-control,
.form--select .css-13cymwt-control {
	font-size: 11px;
	background: transparent;
}
.css-1dimb5e-singleValue {
	font-size: 11px;
}
.css-hlgwow {
	padding: 0 0 0 4px !important;
}
.my-auto {
	margin-block: auto;
}
.slider-item {
	color: #666;
	font-family: Poppins;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	.title {
		color: #000d39;
		font-family: Poppins;
		font-size: 25px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 24px;
		max-width: 650px;
	}
	p {
		margin: 0;
		max-width: 370px;
	}
}

.login-section {
	display: flex;
	min-height: 100vh;
	width: 100%;
	* {
		box-sizing: border-box;
	}
	.pb-5 {
		padding-bottom: 200px !important;
	}
	.login-form-area,
	.login-slider-area {
		width: 50%;
	}
	.login-slider-area {
		padding: 43px;
		display: flex;
		flex-direction: column;
		position: relative;
		overflow: hidden;
		.swiper {
			overflow: visible;
		}
		.swiper-pagination {
			display: flex;
			gap: 16px;
			position: absolute;
			bottom: -300px;
			left: 0;
			width: 100%;
			.swiper-pagination-bullet {
				width: 0;
				flex-grow: 1;
				max-width: 100px;
				height: 3px;
				background: #e3e3e3;
				cursor: pointer;
				&.swiper-pagination-bullet-active {
					background: #000d39;
				}
				@media screen and (max-width: 1199px) {
					background: #666;
					&.swiper-pagination-bullet-active {
						background: #ffffff;
					}
				}
			}
		}
	}
	.login-form-area {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 80px 20px;
		form {
			width: 100%;
			max-width: 320px;
			padding: 25px;
			border-radius: 15px;
			border: 1px solid rgba(232, 232, 232, 0.05);
			background: #fff;
			box-shadow: 1px 1px 5.9px -2px rgba(0, 0, 0, 0.25);
			.title {
				color: #000;
				font-family: Poppins;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 11px;
			}
			.form-group {
				color: #000;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-bottom: 10px;
				.form--label {
					display: block;
					margin-bottom: 10px;
				}



				.form--control {
					width: 100%;
					border-radius: 5px;
					border: 1px solid #000;
					background: #fff;
					height: 28px;
					padding: 0 10px;
					box-shadow: none;
					outline: none;
					&:focus {
						border-color: #0089eb;
					}
				}
			}
			a {
				color: #484c58;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				&:hover {
					color: #0089eb;
				}
			}
			.btn {
				width: 100%;
				font-size: 16px;
				height: 34.441px;
				border-radius: 5px;
				background: #0089eb;
				flex-shrink: 0;
				box-shadow: none;
				outline: none;
				border: none;
				color: var(--white);
				cursor: pointer;
			}
			.mb-29 {
				margin-bottom: 29px;
			}
		}
	}
	.login-slider-area .logo-white {
		display: none;
	}
	@media screen and (min-width: 1199.2px) {
		background: transparent !important;
	}
	@media screen and (max-width: 1199px) {
		.login-slider-area {
			.logo-black {
				display: none;
			}
			.logo-white {
				display: block;
			}
		}
		.pb-5 {
			padding-bottom: 100px !important;
		}
		.login-form-area {
			background: transparent !important;
		}
		.login-slider-area {
			.swiper-pagination {
				bottom: -200px;
			}
			.slider-item {
				color: #d0d0d0;
				p {
					color: #d0d0d0;
				}
				.title {
					color: #ffffff;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.login-form-area,
		.login-slider-area {
			width: 100%;
		}
		.login-slider-area {
			display: none;
		}
		.login-form-area {
			flex-direction: column;
			padding-top: 40px;
			padding-bottom: 40px;
			.logo {
				display: flex;
				justify-content: center;
				margin-bottom: auto;
			}
			.login-form {
				margin-bottom: auto;
			}
		}
	}
}
.d-md-none {
	@media screen and (min-width: 768px) {
		display: none !important;
	}
}
