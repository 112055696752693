.details-content {
    padding: 20px 25px;

    ul {
        display: grid;
        gap: 20px;
        padding-top: 24px;
        font-size: 14px;
        
        li {
            display: flex;
            gap: 20px;
            &:first-child{
                span{
                    text-decoration: underline;
                    @media (max-width: 1399.98px){
                    }
                }
            }
            @media (max-width: 575.98px){
                display: grid;
                gap: 3px;
            }

            b {
                font-size: 14px;
                color: var(--black);
                font-weight: 400;
                min-width: 200px;
                @media (max-width: 1420px){
                    font-size: 14px;
                    min-width: 160px;
                }
                @media (max-width: 1399.98px){
                    font-size: 15px;
                    min-width: 150px;
                }
            }

            span {
                color: #484c58;
                font-weight: 300;
            }
        }
    }
}