body {
	position: relative;
}
.work-visit-content {
	.work-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: -webkit-sticky;
		position: sticky;
		top: -30px;
		padding-top: 4px;
		padding-bottom: 8px;
		z-index: 1000;
		background-color: white;

		@media (max-width: 767.98px) {
			display: grid;
			gap: 20px;
		}
	}
	

	.filtering {
		display: flex;
		align-items: center;
		gap: 5px;
		padding-top: 10px;

		button {
			background: none;
			border: 0;
			outline: none;
			box-shadow: none;
			font-size: 15px;
			color: var(--black);
			font-weight: 400;
			display: flex;
			align-items: center;
			gap: 5px;
			padding: 0 10px;
			height: 31px;
			border-radius: 5px;
			transition: all 0.3s;
			cursor: pointer;

			&:hover {
				background-color: #f8fafb;
			}
		}
	}

	.filter {
		position: relative;

		.filter-dropdown {
			position: absolute;
			min-width: 115px;
			border-radius: 5px;
			filter: drop-shadow(2.828px 2.828px 1.5px rgba(0, 0, 0, 0.06));
			background-color: #ffffff;
			border: 1px solid #dddddd;
			z-index: 1;
			padding: 13px;

			li {
				font-size: 14px;
				font-weight: 400;
				color: var(--black);
				height: 31px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				padding: 0 10px;
				gap: 5px;
				transition: all 0.3s;
				cursor: pointer;

				&:hover {
					background-color: #f8fafb;
				}
			}
		}
	}
}

.mb-auto {
	margin-bottom: auto;
}

.datepicker {
	display: block; 
  }
  
  @media (max-width: 767px) {
	.datepicker {
	  display: none;
	}
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
	.datepicker {
	  display: none;
	}
  }
  

.work-body {
	border-radius: 12px;
	background-color: #f8fafe;
	padding: 30px;
	display: grid;
	gap: 24px;
	margin: 15px 0 30px;
	@media (max-width: 1199.98px) {
		padding: 20px;
	}

	.work-item {
		border-radius: 12px;
		filter: drop-shadow(0 0 1.5px rgba(0, 0, 0, 0.29));
		background-color: #ffffff;
		border: 1px solid #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 110px;
		padding: 10px 30px;
		transition: all 0.3s;
		@media (max-width: 1199.98px) {
			padding: 10px 20px;
		}

		&:hover {
			border-color: #707070;
		}

		@media (max-width: 991.98px) {
			display: grid;
			height: auto;
			gap: 15px;
			padding: 24px;
		}

		.title {
			font-weight: 500;
			color: var(--black);
			@media (max-width: 1199.98px) {
				font-size: 16px;
			}

			@media (max-width: 767.98px) {
				max-width: 66%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.place {
			font-size: 14px;
			color: #1a1919;
			font-weight: 400;
			display: flex;
			align-items: center;
			gap: 5px;
			margin: 5px 0 0;
		}

		.emergency {
			height: 26px;
			border-radius: 5px;
			background-color: #fd6363;
			border: 0;
			outline: none;
			padding: 0 15px;
			font-size: 14px;
			color: var(--white);
			margin-top: 7px;
			cursor: pointer;
		}

		.colored {
			color: #848ba0;
			font-weight: 400;
			display: block;
			font-size: 14px;
		}

		.work-date {
			font-size: 14px;
			color: #1a1919;
			font-weight: 400;
			position: relative;
			@media (max-width: 1199.98px) {
				font-size: 14px;
			}

			span {
				position: absolute;
				bottom: -20px;
				display: block;
				font-size: 12px;
				color: #676767;

				@media (max-width: 991.98px) {
					position: relative;
					bottom: 0;
				}
			}
		}

		.work-complete {
			font-size: 14px;
			color: #1a1919;
			font-weight: 400;
			min-width: 120px;
			display: flex;
			align-items: center;
			@media (max-width: 1199.98px) {
				font-size: 14px;
				min-width: auto;
			}
		}
	}
}

.In,
.in,
.Complete,
.complete,
.Completed,
.completed,
.Cancelled,
.Scheduled,
.scheduled {
	&::before {
		content: "\f111";
		font-weight: 900;
		font-family: "Font Awesome 6 free";
		font-size: 10px;
		margin-right: 5px;
	}
}

.In,
.in {
	&::before {
		color: #e8a544;
	}
}

.Completed,
.completed,
.Complete,
.complete {
	&::before {
		color: #7ed10d;
	}
}

.Scheduled,
.scheduled {
	&::before {
		color: #6089c7;
	}
}

.date-content {
	width: 300px;
	border-radius: 5px;
	filter: drop-shadow(2.828px 2.828px 1.5px rgba(0, 0, 0, 0.06));
	background-color: #ffffff;
	border: 1px solid #dddddd;
	padding: 13px;
	display: grid;
	z-index: 1;
	gap: 10px;
	position: absolute;
	left: 159px;
	top: 32px;
	@media (max-width: 767.98px) {
		left: -123%;
		top: 155px;
	}

	.date-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;

		input {
			border-radius: 2px;
			border: 0.7px solid #d6d6d6;
			font-size: 14px;
			color: var(--black);
			font-weight: 400;
			padding: 2px 6px;

			&::placeholder {
				color: #e3e3e3;
			}
		}
	}

	input[type="date"]::-webkit-inner-spin-button,
	input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}
}

.status-content,
.requester-content {
	width: 240px;
	border-radius: 5px;
	filter: drop-shadow(2.828px 2.828px 1.5px rgba(0, 0, 0, 0.06));
	background-color: #ffffff;
	border: 1px solid #dddddd;
	z-index: 1;
	padding: 13px;
	position: absolute;
	left: 159px;
	top: 32px;

	@media (max-width: 767.98px) {
		left: -23px;
		top: 155px;
	}

	.form-check {
		display: flex;
		align-items: center;
		gap: 5px;
		padding: 5px 0;
	}
}


/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 55px;
	height: 22px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  /* The slider */
  .slider {
	position: absolute;
	border-radius: 10px;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	border-radius: 10px;
	position: absolute;
	content: "";
	height: 14px;
	width: 21px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #2196F3;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }