.assignment-content {
    padding: 20px;

    .assignment-user {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 20px 0;
        .list {
            display: grid;
            gap: 8px;

            li {
                color: #484c58;
                font-weight: 300;
                font-size: 14px;
                @media (max-width: 1420px){
                    font-size: 14px;
                }
                @media (max-width: 575.98px){
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 14px;
                }
                svg{
                    font-size: 14px;
                }
                span {
                    width: 25px;
                    height: 25px;
                    border-radius: 13px;
                    background-color: #e4ebf8;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: var(--black);
                }
            }
        }
    }
    .technicians{
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 10px;
        span{
            font-size: 14px;
            color: #484c58;
            font-weight: 300;
            @media (max-width: 1420px){
                font-size: 14px;
            }
        }
    }
}